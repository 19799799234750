import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BookPreview from '../components/book-preview'
import Quote from '../components/quote'

class BooksPage extends React.Component {
  render() {
    const about = this.props.data.contentfulAbout
    const books = this.props.data.allContentfulBook.nodes
    let sectionCount = 0
    return (
      <Layout location={this.props.location}>
        <section className="top">
          <Helmet title={`Books`} />
          <h1>Discover the latest from Angie</h1>
          <ul className="book-list">
            {books.map((book) => {
              return (
                <li key={book.slug}>
                  <BookPreview book={book} />
                </li>
              )
            })}
          </ul>
        </section>
        <Quote
          quote={about.quote.childMarkdownRemark.html}
          quoteImage={about.quoteImage.gatsbyImageData}
          sectionCount={(sectionCount += 1)}
        />
      </Layout>
    )
  }
}

export default BooksPage

export const pageQuery = graphql`
  query BooksQuery {
    allContentfulBook(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        blurb {
          childMarkdownRemark {
            html
          }
        }
        heroImage {
          gatsbyImageData(height: 384, width: 256, layout: CONSTRAINED)
        }
        publisher
        slug
        title
      }
    }
    contentfulAbout(id: { eq: "f34952d8-f0ee-59dd-950e-785cf3ada390" }) {
      quote {
        childMarkdownRemark {
          html
        }
      }
      quoteImage {
        gatsbyImageData(width: 969, layout: CONSTRAINED)
      }
    }
  }
`
