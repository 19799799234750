import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import closeX from '../svg/close-x.svg'

export default function BookPreview({ book }) {
  const [showDetails, setShowDetails] = React.useState(false)
  return (
    <>
      <Link to={`/${book.slug}/`}>
        <GatsbyImage
          image={book.heroImage.gatsbyImageData}
          alt=""
          style={{ minHeight: `394px` }}
        />
        <div className="pad-sides">
          <h3>{book.title}</h3>
        </div>
      </Link>
      <div className="pad-sides">
        <div>
          {showDetails ? (
            <div className="book-details">
              <button
                className="hide-details"
                title="Hide Details"
                onClick={() => setShowDetails(false)}
              >
                <span
                  className="close-x"
                  style={{
                    backgroundImage: `url(${closeX})`,
                  }}
                ></span>
              </button>
              <div className="pad-sides clear-both">
                <div className="blurb-pub text-align-left font-size-smaller">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: book.blurb.childMarkdownRemark.html,
                    }}
                  />
                  <span className="publisher">
                    <strong>{book.publisher}</strong>
                  </span>
                </div>
                <Link to={`/${book.slug}/`}>
                  <h3 className="mt">{book.title}</h3>
                </Link>
                <Link
                  className="button mobile-full"
                  to={`/${book.slug}/`}
                >
                  Learn More
                </Link>
              </div>
            </div>
          ) : null}
          <button className="show-details" onClick={() => setShowDetails(true)}>
            Details &gt;
          </button>
        </div>
        <Link className="button mobile-full" to={`/${book.slug}/`}>
          Learn More
        </Link>
      </div>
    </>
  )
}
